//切位订单详情
import Q from 'q';
import moment from 'moment';
import web_common_buyerTourCutOrder_getCutOrderDetail from '@/lib/data-service/default/web_common_buyerTourCutOrder_getCutOrderDetail';
import web_common_lookDepartInform from '@/lib/data-service/default/web_common_lookDepartInform';
import { setTimeFormat } from '../../component/util'


export default {
  components: {},
  data() {
    return {
      orderStatusList: [],
      currentStatus: [],
      orderInfoData: {},
      tourPeriod: [],
      setTimeFormat: setTimeFormat
    };
  },
  computed: {

  },
  methods: {
    req_cut_order_details(query) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_buyerTourCutOrder_getCutOrderDetail(query);
        })
        .then(function (res) {
          _this.orderInfoData = res;
          _this.orderInfoData.departureTime = JSON.parse(res.teamTimes).reduce(function (acc, item, index) {
            if (index === 0) {
              // eslint-disable-next-line no-return-assign
              return acc = item.team;
            } else {
              // eslint-disable-next-line no-return-assign
              return acc += `,${item.team}`;
            }
          }, '');
          _this.tourPeriod = res.orderDetailResults;
          _this.tourPeriod.forEach(function (item) {
            moment(item.uploadTravellerDeadline).format('YYYYMMDD') >= moment(new Date()).format('YYYYMMDD') ? item.retainage = true : item.retainage = false;
          })
        })
    },
    req_look_advice_note(params) {
      return Q.when()
        .then(function () {
          return web_common_lookDepartInform(params);
        })
    },
    //返回
    to_cut_ordre_list() {
      this.$router.push({
        name: 'distributor-tour-back-cut-order-list'
      })
    },
    //子订单详情
    to_tour_period(id) {
      const query = { id };
      this.$router.push({
        name: 'distributor-tour-back-cut-team-details',
        query
      })
    },
    //上次出游人名单
    upload_name_list(id) {
      const query = { id };
      this.$router.push({
        name: 'distributor-tour-back-upLoad-nameList',
        query,
      })
    },
    // 支付尾款
    to_cut_order_payment(id, orderNo) {
      let cutOrderNo = this.orderInfoData.cutOrderNo
      const query = { id, cutOrderNo };
      this.$router.push({
        name: 'distributor-tour-back-final-payment',
        query,
      })
    },
    // 查看通知单
    look_advice_note(orderId) {
      const _this = this;
      const params = { orderId }
      Q.when()
        .then(function () {
          return _this.req_look_advice_note(params);
        })
        .then(function (res) {
          if (!res.url) {
            _this.$message.error('返回url为空，请联系管理员')
            return;
          }
          window.open(res.url, '_blank')
        })
    }
  },
  watch: {

  },
  mounted() {

  },
  activated() {
    this.req_cut_order_details(this.$route.query)
  }

}
